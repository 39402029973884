<template>
<div>
    <!-- <navbar flag="overview" /> -->
    <b-card no-body>
        <b-card-header v-if="loggedUser.role == 'Super Admin'">
            <h3>Lists</h3>
            <b-form-group
                
                label="Search"
                label-cols-sm="3"
                label-align-sm="left"
                label-size="md"
                label-for="filterInput"
                class="mb-0"
            >
                <b-input-group>
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                    />
                </b-input-group>
            </b-form-group>
        </b-card-header>
        <b-card-body v-if="loggedUser.role == 'Super Admin'">
            <b-table
                striped
                hover
                responsive
                class="position-relative"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
            >
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item
                            v-b-modal.modal-center
                            @click="onEdit(data.item)"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>
            </b-table>
        </b-card-body>
        <b-modal
            size="lg"
            id="modal-center"
            centered
            title="Company Form"
            ok-title="Save"
            @ok="onSave"
            ok-only
        >
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        label="Company Name"
                        label-for="h-first-name"
                        label-cols-md="2"
                    >
                        <b-form-input
                            v-model="item.name"
                            id="h-first-name"
                            placeholder="Enter Tractor.."
                        />
                    </b-form-group>
                    <b-form-group
                        label="Company Email"
                        label-for="h-first-name"
                        label-cols-md="2"
                    >
                        <b-form-input
                            v-model="item.email"
                            id="h-first-name"
                            placeholder="Enter Tractor.."
                        />
                    </b-form-group>
                    <b-form-group
                        label="Company Brand"
                        label-for="h-first-name"
                        label-cols-md="2"
                    >
                        <b-form-input
                            v-model="item.brand"
                            id="h-first-name"
                            placeholder="Enter Tractor.."
                        />
                    </b-form-group>
                    <b-form-group
                        label="Global Contact"
                        label-for="h-first-name"
                        label-cols-md="2"
                    >
                        <b-form-input
                            v-model="item.globalcontact"
                            id="h-first-name"
                            placeholder="Enter Tractor.."
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </b-card>
    <overview-form @fetchData="fetchCompany" />
</div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import OverviewForm from "./Form.vue";
import {
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroup
} from "bootstrap-vue";
import axios from "axios";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        Navbar,
        OverviewForm,
        BBreadcrumb,
        BBreadcrumbItem,
        BTable,
        BCard,
        BCardHeader,
        BCardBody,        
        BModal,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BDropdown,
        BDropdownItem,
        BInputGroup,
        ToastificationContent
    },
    data() {
        return {
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: " ",
            filterOn: [],
            fields: [
                {
                    key: "name",
                    label: "Company Name",
                },
                {
                    key: "email",
                    label: "Company Email",
                },
                {
                    key: "brand",
                    label: "Brand",
                },                
                {
                    key: "actions",
                    label: "ACTION",
                },
            ],
            items: [],
            item: {
                name: null,
                email: null,
                brand: null,
                globalcontact: null
            },
            loggedUser: []
        }
    },
    created() {
        this.loggedUser = getUserData()

        this.onLoad()
    },
    methods: {
        onLoad() {
            if (this.loggedUser.role == 'Super Admin') {
                axios.get(`${serverUri}/api/company/getcompany/${null}`).then((res) => {
                    this.items = res.data
                })
            }
        },
        onSave() {
            axios
                .post(`${serverUri}/api/company/updatecompany`, {
                    ...this.item,
                })
                .then((res) => {
                    this.items = res.data;

                    this.showToast(
                        "success",
                        "Success",
                        "The company has been saved."
                    );
                });
        },
        onEdit(data) {
            this.item = data;
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: "BellIcon",
                    text: text,
                    variant,
                },
            });
        },
        fetchCompany() {
            this.onLoad();
        }
    }
    
};
</script>
